import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { GiWallet } from "react-icons/gi";
// import {
//   createBuyOffer,
//   createSellOffer,
// } from "../redux/helpers/api_functions";
import {
  getOrderBook,
  getTradeHist,
  getUserBalance,
  getUserOrder,
} from "../redux/actions/coinDBAction";
import {
  N_createBuyOffer,
  N_createSellOffer,
  N_executeOrder,
} from "../redux/helpers/api_functions_new";
import createSocketClient from "../redux/helpers/socket";
import {
  BUY_MARKET_PRICE,
  SELL_MARKET_PRICE,
  SET_BUY_ORDER_BOOK,
  SET_ORDER_BOOK,
  SET_SELL_ORDER_BOOK,
  SET_TRADE_HISTORY,
} from "../redux/constant";
import { mul, round } from "../redux/helpers/Math";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";
import { user_logout } from "../redux/actions/authActions";
// import socket from "../redux/helpers/events";

export default function BuyNSell(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [bloading, setbLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(props.activeTab || 0);
  const [atPrice, setATPrice] = React.useState(0);
  const [atsPrice, setATSPrice] = React.useState(0);
  const [cprice, setCprice] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [asmount, setSAmount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [stotal, setSTotal] = React.useState(0);
  const [stopLimit, setStoplimit] = React.useState(0);
  const [total_inr, setTotalInr] = React.useState(0);
  const [wallet_details, setWalletDetails] = React.useState([]);
  const [buybalance, setbuybalance] = React.useState(0);
  const [sellbalance, setsellbalance] = React.useState(0);
  const coin = props?.match?.params?.id
    ? props.match.params.id.split("-")
    : "btc-inr".split("-");
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const { coins, wallet, paired_curency_price, buymarket, sellmarket } =
    useSelector((state) => state.coinDBReducer);
  let current_coin = coins[(coin[0] + coin[1]).toUpperCase()];
  const [price, setprice] = React.useState(0);
  let time = 0;

  function setCurrentBuySellTokenBalance() {
    wallet_details.map((item, i) => {
      if (item.symbol === coin[1].toUpperCase()) {
        setbuybalance(item.avl_balance);
      } else if (item.symbol === coin[0].toUpperCase()) {
        setsellbalance(item.avl_balance);
      }
    });
  }

  function getCurrentBuySellTokenBalance() {
    let total = 0;
    let final_data = Object.keys(wallet).map((res, i) => {
      let wallet_type = wallet[res]?.symbol.toUpperCase();
      let rate = getCoinRate(coins, wallet_type);
      let inr_val = rate * getRound(wallet[res]?.balance);
      total = total + parseFloat(inr_val);
      return {
        id: wallet[res]?.id,
        icon: wallet[res]?.icon,
        symbol: wallet[res]?.symbol.toUpperCase(),
        name: wallet[res]?.name,
        status: wallet[res]?.status,
        withdral_fee: wallet[res]?.withdrawal_fee,
        locked: getRound(wallet[res]?.locked),
        address: wallet[res]?.wallet_address,
        balance: getRound(wallet[res]?.balance),
        avl_balance: getRound(wallet[res]?.balance - wallet[res]?.locked),
        inr_balance: inr_val,
      };
    });
    setWalletDetails(final_data);
    setTotalInr(total);
    // console.log("fdata: ", final_data);
    setTimeout(() => setLoading(false), 800);
  }

  useEffect(() => {
    setCurrentBuySellTokenBalance();
  }, [coin, wallet_details]);

  useEffect(() => {
    Object.values(coins)?.map((d, i) => {
      if (d.symbol === coin[0].toUpperCase()) {
        setprice(
          Number(d.current_price_inr) /
            Number(
              paired_curency_price
                ? paired_curency_price[coin[1].toUpperCase()]
                  ? paired_curency_price[coin[1].toUpperCase()]
                  : 1
                : 1
            )
        );
      }
    });
  });
  useEffect(() => {
    // console.log("wallet", coin_wallet, currency_wallet);
    if (price && time == 0) {
      setATPrice(getRound(price));
      setATSPrice(getRound(price));
      setCprice(getRound(price));
      time = 1;
    }
  }, [price, wallet]);

  // function getCoinRate(coin, currency) {
  //   let coins1 = Object.values(coins);
  //   // console.log("coins: ",coins1);
  //   let res = coins1.find((d) => d.symbol === coin);
  //   return res?.current_price_inr ? res.current_price_inr : 0;
  // }

  useEffect(() => {
    getCurrentBuySellTokenBalance();
  }, [wallet]);

  // useEffect(() => {
  //   // setTimeout(() => {
  //   //   setInterval(() => {
  //       getCurrentBuySellTokenBalance();

  //   //   }, 10000);
  //   // }, 10000);
  // }, []);

  useEffect(() => {
    // dispatch({
    //   type: SELL_MARKET_PRICE,
    //   data: {
    //     marketprice: 0,
    //     marketvolume: 0,
    //     active: 0,
    //   },
    // });
    const mprice = round(buymarket.marketprice);
    const mvolume = round(buymarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setActiveTab(buymarket.active);
    setATSPrice(mprice);
    setSAmount(mvolume);
    setSTotal(totalinr);
  }, [buymarket]);

  useEffect(() => {
    // dispatch({
    //   type: BUY_MARKET_PRICE,
    //   data: {
    //     marketprice: 0,
    //     marketvolume: 0,
    //     active: 0,
    //   },
    // });
    const mprice = round(sellmarket.marketprice);
    const mvolume = round(sellmarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setATPrice(mprice);
    setAmount(mvolume);
    setTotal(totalinr);
    setActiveTab(sellmarket.active);
  }, [sellmarket]);

  function buyCoin(atPrice, amount, c, cp) {
    setbLoading(true);
    N_createBuyOffer(
      atPrice,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice
    )
      .then((d) => {
        console.log(d.result);
        if (d.status == 200) {
          // console.log("res buy: ", d);
          setbLoading(false);
          NotificationManager.success(d.message);
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          getCurrentBuySellTokenBalance();
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          N_executeOrder(
            d.result.order_id,
            user?.params ? user.params.token : user.token,
            d.result.type
          ).then((d) => {
            if (d.status == 200) {
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              getCurrentBuySellTokenBalance();
              dispatch(
                getUserOrder(user?.params ? user.params.token : user.token)
              );
            }
          });
        } else if (d.status === 401) {
          dispatch(
            user_logout(() => {
              props?.history?.push("/login");
            })
          );
        } else if (d.status == 400) {
          // console.log("res buy: ", d);
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          getCurrentBuySellTokenBalance();
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          NotificationManager.success(d.message);
        }

        // dispatch(getUserOrder(token));
        // dispatch(getUserBalance(token));
        // dispatch(getOrderBook(coin[0], coin[1], () => {}));
        // dispatch(getTradeHist(coin[0], coin[1], () => {}));
        setbLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
    // createBuyOffer(atPrice, amount, c, cp, token)
    //   .then((d) => {
    //     if (d.status == 0) {
    //       NotificationManager.error(d.msg);
    //     } else if (d.status == 1) {
    //       NotificationManager.info(d.msg);
    //     }
    //     dispatch(getUserOrder(token));
    //     dispatch(getUserBalance(token));
    //     dispatch(getOrderBook(coin[0], coin[1], () => {}));
    //     dispatch(getTradeHist(coin[0], coin[1], () => {}));
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  function sellCoin(atPrice, amount, c, cp) {
    setLoading(true);
    // console.log("buy user: ", user);
    N_createSellOffer(
      atPrice,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice
    )
      .then((d) => {
        if (d.status == 200) {
          // console.log("res sell: ", d);
          NotificationManager.success(d.message);
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          getCurrentBuySellTokenBalance();
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          N_executeOrder(
            d.result.order_id,
            user?.params ? user.params.token : user.token,
            d.result.type
          ).then((d) => {
            if (d.status == 200) {
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              getCurrentBuySellTokenBalance();
              dispatch(
                getUserOrder(user?.params ? user.params.token : user.token)
              );
            }
          });
        } else if (d.status === 401) {
          dispatch(
            user_logout(() => {
              props?.history?.push("/login");
            })
          );
        } else if (d.status == 400) {
          // console.log("res sell: ", d);
          dispatch(
            getUserBalance(user?.params ? user.params.token : user.token)
          );
          getCurrentBuySellTokenBalance();
          dispatch(getUserOrder(user?.params ? user.params.token : user.token));
          NotificationManager.success(d.message);
        }
        // dispatch(getUserOrder(token));
        // dispatch(getUserBalance(token));
        // dispatch(getOrderBook(coin[0], coin[1], () => {}));
        // dispatch(getTradeHist(coin[0], coin[1], () => {}));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <div className="ps-1 py-0 mt-2">
        <div className="card">
          <div className="card-header">Stop Limit</div>

          <div className="card-body order_height" id="open-order">
            <div className="row">
              <div className="col-lg-6">
                <div className="">
                  <select
                    className="custom-select bg-light text-secondary border buy-sell-form-bg buy-sell-theme d-none"
                    value={stopLimit}
                    onChange={(e) =>
                      setStoplimit(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                  >
                    <option value={0}>Limit</option>
                    <option value={1}>Stop Limit</option>
                  </select>
                </div>
                <div className="">
                  {stopLimit === 1 ? (
                    <div className="input-group exchange_input_grp mb-2">
                      <span className="input-group-text">STOP PRICE</span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value="0"
                      />
                      <span className="input-group-text">INR</span>
                    </div>
                  ) : null}

                  {isLoggedIn ? (
                    <div className="row mb-2">
                      <div className="col-lg-6">
                        <span className="text-green fs-12 fw-bold">
                          BUY {coin[0].toUpperCase()}
                        </span>
                      </div>
                      <div className="col-lg-6 text-end fs-12 mb-2 text-muted">
                        <span className="mx-2" title="wallet">
                          <i className="fa fa-wallet"> </i>
                        </span>
                        {coin[1] !== "inr"
                          ? buybalance.toFixed(8) + " " + coin[1].toUpperCase()
                          : buybalance.toFixed(4) + " INR"}
                      </div>
                    </div>
                  ) : null}

                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Price
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={atPrice}
                      onChange={(e) => {
                        setATPrice(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                        setTotal(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") * amount
                        );
                      }}
                    />
                    <span className="input-group-text bg-transparent">
                      {coin[1].toUpperCase()}
                    </span>
                  </div>
                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Amount
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={amount}
                      onChange={(e) => {
                        setAmount(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                        setTotal(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") * atPrice
                        );
                      }}
                    />

                    <span className="input-group-text">
                      {coin[0].toUpperCase()}
                    </span>
                  </div>
                  <div className="input-group mb-3 exchange_input_grp">
                    <span className="input-group-text buy-sell-form-bg buy-sell-theme ">
                      Total
                    </span>

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme "
                      value={total}
                      onChange={(e) => {
                        setAmount(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") / atPrice
                        );
                        setTotal(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                      }}
                    />

                    <span className="input-group-text">
                      {coin[1].toUpperCase()}
                    </span>
                  </div>
                  {isLoggedIn ? (
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div
                          className="buy_sell_amount_picker"
                          onClick={() => {
                            setTotal(
                              Number(buybalance ? buybalance * 0.25 : 0)
                            );
                            setAmount(
                              Number(
                                (buybalance ? buybalance * 0.25 : 0) / atPrice
                              )
                            );
                          }}
                        >
                          25%
                        </div>
                        <div
                          className="buy_sell_amount_picker"
                          onClick={() => {
                            setTotal(buybalance ? buybalance * 0.5 : 0);
                            setAmount(
                              buybalance ? (buybalance * 0.5) / atPrice : 0
                            );
                          }}
                        >
                          50%
                        </div>
                        <div
                          className="buy_sell_amount_picker btn btn-default"
                          onClick={() => {
                            setTotal(buybalance ? buybalance * 0.75 : 0);
                            setAmount(
                              buybalance ? (buybalance * 0.75) / atPrice : 0
                            );
                          }}
                        >
                          75%
                        </div>
                        <div
                          className="buy_sell_amount_picker"
                          onClick={() => {
                            setTotal(buybalance ? buybalance : 0);
                            setAmount(buybalance ? buybalance / atPrice : 0);
                          }}
                        >
                          100%
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="d-grid mt-2">
                    <button
                      className="btn btn-success"
                      disabled={bloading}
                      onClick={() => {
                        if (isLoggedIn) {
                          buyCoin(atPrice, amount, coin[0], coin[1]);
                        } else {
                          NotificationManager.error(
                            "First login then perform buy/sell"
                          );
                        }
                      }}
                    >
                      {bloading ? (
                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                      ) : null}
                      BUY {coin[0].toUpperCase()}
                    </button>
                  </div>
                  <div className="text-muted text-center small mt-1">
                    Fee: Maker fee: 0.1%| Taker fee: 0.1%
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* sell tab */}

                <div id="order-history">
                  <div className="">
                    {isLoggedIn ? (
                      <div className="row mb-2">
                        <div className="col-lg-6">
                          <span className="text-red fw-bold fs-12">
                            SELL {coin[0].toUpperCase()}
                          </span>
                        </div>
                        <div className="col-6 text-end fs-12 mb-2 text-muted">
                          <span className="me-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span>
                          {sellbalance
                            ? coin[1] !== "inr"
                              ? sellbalance.toFixed(8)
                              : sellbalance.toFixed(4)
                            : 0}{" "}
                          {coin[0].toUpperCase()}
                        </div>{" "}
                      </div>
                    ) : null}

                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Price
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={atsPrice}
                        onChange={(e) => {
                          setATSPrice(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setSTotal(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") * asmount
                          );
                        }}
                      />
                      <div className="input-group-text">
                        {coin[1].toUpperCase()}
                      </div>
                    </div>
                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Amount
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={asmount}
                        onChange={(e) => {
                          setSAmount(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                          setSTotal(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") * atsPrice
                          );
                        }}
                      />
                      <span className="input-group-text">
                        {" "}
                        {coin[0].toUpperCase()}
                      </span>
                    </div>

                    <div className="input-group mb-3 exchange_input_grp">
                      <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Total
                      </span>

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={stotal}
                        onChange={(e) => {
                          setSAmount(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") / atsPrice
                          );
                          setSTotal(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          );
                        }}
                      />
                      <span className="input-group-text">
                        {coin[1].toUpperCase()}
                      </span>
                    </div>
                    {isLoggedIn ? (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                          <span
                            className="buy_sell_amount_picker"
                            onClick={() => {
                              setSAmount(sellbalance ? sellbalance * 0.25 : 0);
                              setSTotal(
                                sellbalance ? atsPrice * sellbalance * 0.25 : 0
                              );
                            }}
                          >
                            25%
                          </span>
                          <span
                            className="px-1 buy_sell_amount_picker"
                            onClick={() => {
                              setSAmount(sellbalance ? sellbalance * 0.5 : 0);
                              setSTotal(
                                sellbalance ? atsPrice * sellbalance * 0.5 : 0
                              );
                            }}
                          >
                            50%
                          </span>
                          <span
                            className="pl-1 buy_sell_amount_picker"
                            onClick={() => {
                              setSAmount(sellbalance ? sellbalance * 0.75 : 0);
                              setSTotal(
                                sellbalance ? atsPrice * sellbalance * 0.75 : 0
                              );
                            }}
                          >
                            75%
                          </span>
                          <span
                            className="pl-1 buy_sell_amount_picker"
                            onClick={() => {
                              setSAmount(sellbalance ? sellbalance : 0);
                              setSTotal(
                                sellbalance ? atsPrice * sellbalance : 0
                              );
                            }}
                          >
                            100%
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div className="d-grid mt-2">
                      <button
                        className="btn btn-danger"
                        disabled={loading}
                        onClick={() => {
                          if (isLoggedIn) {
                            sellCoin(atsPrice, asmount, coin[0], coin[1]);
                          } else {
                            NotificationManager.error(
                              "First login then perform buy/sell"
                            );
                          }
                        }}
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                        ) : null}
                        SELL {coin[0].toUpperCase()}
                      </button>
                    </div>
                    <div className="text-muted small text-center mt-1">
                      Fee: Maker fee: 0.1%| Taker fee: 0.1%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
